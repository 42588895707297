import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import Divider from '../../components/ui/Divider/Divider';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import '../sobre/about.scss';

const AboutBlockchain: React.FC = () => {

    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="O que é Blockchain?"
                url="sobre-blockchain/"
                description="O que é Blockchain?"
            />
            <PageBanner className="page-banner-single-item">
                <Fragment>
                    <h1>
                        O que é Blockchain?
                    </h1>
                </Fragment>
                <Fragment>
                </Fragment>
            </PageBanner>

            <PageSection>
                <Container>
                    <Group size={1}>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <p>
                            Em tradução livre do inglês, Blockchain é uma "cadeia de blocos". 
                            Esta sequência de blocos de dados contendo as transações da rede Bitcoin é imutável e 100% transparente. 
                            Saiba tudo sobre essa tecnologia que permitiu a criação de moedas como o Bitcoin.
                            </p>
                            <p>
                            Uma das principais tecnologias e inovações dos últimos anos. Para muitos especialistas, essa é a melhor resposta para a pergunta o que é Blockchain. 
                            No entanto, algumas pessoas ainda não entendem esse conceito, por isso, ao longo desse conteúdo vamos explicar de forma simples e prática.
                            </p>
                        </Block>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <p>
                            <h2>O que é Blockchain?</h2>
                            Blockchain pode ser traduzida como corrente de blocos. De uma forma simples, trata-se de uma tecnologia que agrupa um conjunto de informações que se conectam por meio de criptografia. 
                            Assim, realizando transações e outras operações de forma segura.
                            </p>
                            <p>
                            A Blockchain é importante para garantir que ninguém consiga efetuar fraudes, tornando cada moeda rastreável desde o momento de sua criação. 
                            Funciona como um grande livro-caixa público, em que são registradas todas as transações, de forma rápida e segura.
                            </p>
                            <p>
                            A grande inovação da Blockchain foi armazenar os dados de forma sequencial, porém sem a necessidade de uma entidade coordenando o processo. 
                            <b> Os próprios usuários da rede conseguem verificar de forma simples e praticamente sem custo</b> se as regras estão sendo cumpridas.
                            </p>
                            <p>
                            Ao contrário das redes privadas, a Blockchain permite que os dados sejam compartilhados por todos, sem necessidade de permissão. 
                            Os incentivos da rede foram desenhados para que atacantes tenham um alto dispêndio de energia, enquanto o trabalho para os usuários, protegendo a rede e validando transações fosse mínimo.
                            </p>
                        </Block>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <p>
                            <h2>Como surgiu a Blockchain?</h2>
                            Esta ideia não surgiu do vácuo, pois grupos de cripto-anarquistas tentavam há mais de três décadas criar uma moeda digital que tivesse certo grau de privacidade e segurança. 
                            Em outubro de 2008, um desconhecido sob o pseudônimo de Satoshi Nakamoto divulgou um paper sobre o <a href='https://coinext.com.br/criptomoedas/bitcoin' target='_blank'>Bitcoin</a>, “um novo sistema de dinheiro eletrônico”.
                            </p>
                            <p>
                            Um dos aspectos mais importantes de qualquer moeda é garantir que ela não seja falsificável ou clonada, conhecido como gasto duplo. 
                            Este problema foi tão difícil de ser superado que mesmo com os inúmeros avanços da computação, todos os meios de pagamento <b>continuam dependendo da centralização</b>.
                            </p>
                            <p>
                            PayPal, WePay, MercadoPago, PagSeguro, e todos estes processadores de pagamento dependem de uma empresa controlando os saldos de cada conta. Não há nada de errado neste processo, que é simples e eficiente. 
                            O problema é que depende na confiança de um terceiro, mesmo que seja uma grande empresa, banco ou governo.
                            </p>
                            {/* <img href='https://assets-global.website-files.com/5e22092f70a6792c98387acc/5f3ac828a5594c7876ec6fcf_caracter%C3%ADsticas%20blockchain.png'> */}
                        </Block>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <p>
                            <h2>Como funciona a Blockchain</h2>
                            A Blockchain ordena blocos de informação em cadeia sequencial, e originalmente foi denominada de Timechain, ou cadeia temporal. 
                            Ela é importante para garantir que <b>ninguém consiga fraudar transações</b>, já que os saldos de cada endereço dependem das movimentações passadas.
                            </p>
                            <p>
                            Ao contrário de uma conta bancária, em que um banco de dados armazena os saldos, podendo inclusive apagar o histórico de períodos mais longos, a Blockchain registra apenas as movimentações. 
                            Para calcular o saldo, deve-se percorrer todo o histórico da rede, acompanhando as transações desde a emissão de cada moeda.
                            </p>
                            <p>
                            A beleza do negócio é que esta validação é rápida, consome pouquíssima energia, e está armazenada em cada um dos usuários rodando o software da rede Bitcoin. 
                            Existem inclusive sites que oferecem gratuitamente este serviço de consulta.
                            </p>
                            <p>
                            Um novo bloco é gerado a cada 10 minutos em média, e a competição para minerar o próximo inicia-se assim que uma solução válida para o bloco anterior é anunciada. 
                            Quem confere esta resposta (hash) fornecida pelos mineradores são os <b>nós (nodes)</b>, os usuários comuns da rede Bitcoin.
                            </p>
                            <p>
                            Ou seja, os mineradores encontram a solução (hash) que interliga o novo bloco ao anterior, mas são os usuários da rede que fazem esta validação, ao mesmo tempo que decidem qual a sequência mais longa de blocos para seguir.
                            </p>
                        </Block>
                        <center><p>Conteúdo extraído do site <a href='https://coinext.com.br/o-que-e-blockchain' target='_blank'>Cointext</a> continue a leitura no site.</p></center>
                    </Group>

                </Container>
            </PageSection>

        </PageLayout>
    );
};

export default AboutBlockchain;
